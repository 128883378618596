<script>
//import axios from "axios";
import { authMethods, adminComputed, adminMethods } from "@/state/helpers";

import i18n from "../i18n";

//import simplebar from "simplebar-vue";

/**
 * Nav-bar Component
 */
export default {
    data() {
        return {
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
        };
    },
    components: {
        //simplebar
    },
    mounted() {
        //this.value = this.languages.find((x) => x.language === i18n.locale);
        // this.text = this.value.title;
        //this.flag = this.value.flag;
    },
    computed: {
        ...adminComputed,
        availableBranches() {
            //return branches for the logged in user
            return this.core.branch_config ? this.core.branch_config : [];
        },
        selectedBranchName() {
            // return the name of the selected branch to be displayed in the branch selection dropdown
            if (this.selected_branch_id == -1) {
                return "Select a branch";
            } else {
                let branch = this.availableBranches.find(
                    (branch) => branch.id == this.selected_branch_id
                );
                return branch ? branch.name : "Error finding branch";
            }
        },
        userName() {
            let name = "";
            if (this.core != null) {
                name = this.core.name;
            }
            return name;
        },
    },
    methods: {
        ...authMethods,
        ...adminMethods,
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        logMeOut() {
            window.axios
                .get("api/auth/logout")
                .then(() => {
                    this.logOut().then(() => {
                        this.handleLogout();
                        window.localStorage.removeItem("x_token");
                        window.localStorage.removeItem("auth.currentUser");
                        this.$router.push({ name: "login" });
                    });
                })
                .catch(() => {
                    this.handleLogout();
                });
        },

        selectBranch(branch_id) {
            //set the selected branch id in the admin vuex store
            this.setSelectedBranch(branch_id);
        },
        isBranchSelected(branch_id) {
            if (this.selected_branch_id == branch_id) {
                return "text-decoration: underline !important;";
            } else {
                return "";
            }
        },
    },
};
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link tag="a" to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img
                                src="@/assets/images/logo.svg"
                                alt
                                height="22"
                            />
                        </span>
                        <span class="logo-lg">
                            <img
                                src="@/assets/images/logo-dark.png"
                                alt
                                height="17"
                            />
                        </span>
                    </router-link>

                    <!-- <router-link tag="a" to="/" class="logo logo-light"> -->
                    <!-- <span class="logo-sm">
              <img src="@/assets/images/logo-light.svg" alt height="22" />
            </span> -->
                    <span class="logo-lg">
                        <img
                            src="@/assets/images/client_logo.webp"
                            alt
                            height="70"
                        />
                    </span>
                    <!-- </router-link> -->
                </div>

                <button
                    id="vertical-menu-btn"
                    type="button"
                    class="btn btn-sm px-3 font-size-16 header-item"
                    @click="toggleMenu"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>

            <div class="d-flex">
                <div class="dropdown d-none d-lg-inline-block ml-1">
                    <button
                        type="button"
                        class="btn header-item noti-icon"
                        @click="initFullScreen"
                    >
                        <i class="bx bx-fullscreen"></i>
                    </button>
                </div>

                <!-- BRANCH SELECTION DROPDOWN -->
                <b-dropdown right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <span class="d-none d-xl-inline-block ml-1">
                            {{ selectedBranchName }}
                        </span>
                        <i
                            class="mdi mdi-chevron-down d-none d-xl-inline-block"
                        ></i>
                    </template>
                    <!-- branches-->
                    <b-dropdown-item
                        v-for="branch in availableBranches"
                        :style="isBranchSelected(branch.id)"
                        @click="selectBranch(branch.id)"
                        :key="branch.id"
                        class="dropdown-item text-primary"
                    >
                        {{ branch.name }}
                    </b-dropdown-item>
                </b-dropdown>

                <!-- LOGGED IN USER DROPDOWN -->
                <b-dropdown right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <img
                            class="rounded-circle header-profile-user"
                            src="@/assets/images/users/avatar-1.jpg"
                            alt="Header Avatar"
                        />
                        <span class="d-none d-xl-inline-block ml-1">{{
                            userName
                        }}</span>
                        <i
                            class="mdi mdi-chevron-down d-none d-xl-inline-block"
                        ></i>
                    </template>
                    <!-- item-->
                    <b-dropdown-divider></b-dropdown-divider>
                    <a @click="logMeOut" class="dropdown-item text-danger">
                        <i
                            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
                        ></i>
                        {{ $t("navbar.dropdown.henry.list.logout") }}
                    </a>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>
